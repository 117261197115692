import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import CallToAction from '../../molecules/CallToAction/CallToAction';
import { BlogCardDeckStyles } from '../../styles/Components/BlogCardDeck/BlogCardDeckStyles';
import BlogCard from './BlogCard';

const BlogCardDeck = ({ id }) => {
  const { blogs } = useStaticQuery(query);

  return (
    <BlogCardDeckStyles>
      <Container className="justify-content-center">
        <Row className="heading-info">
          <Col>
            <p className="blog-heading-kicker">Related resources</p>
            <h2>Check out our latest blogs</h2>
            <div className="subhead">
              Shopmonkey helps auto shops to work smarter, faster and grow their
              business with an all-in-one platform.
            </div>
            <CallToAction
              link="/blog"
              value="View All Blog Posts"
              variant="primary"
            />
          </Col>
        </Row>
        <Row>
          {blogs.nodes
            .filter(blog => blog.id !== id)
            .slice(0, 3)
            .map(card => (
              <Col md={6} lg={4} key={card.id} className="card-wrapper">
                <BlogCard card={card} />
              </Col>
            ))}
        </Row>
      </Container>
    </BlogCardDeckStyles>
  );
};

const query = graphql`
  {
    blogs: allDatoCmsBlogPost(
      sort: { fields: publishDate, order: DESC }
      filter: { meta: { status: { eq: "published" } } }
      limit: 4
    ) {
      nodes {
        id
        title
        excerpt
        slug
        publishDate(formatString: "MMMM DD, YYYY")
        category {
          name
          tagColor {
            hex
          }
          tagBackground {
            hex
          }
        }
        image {
          gatsbyImageData(
            imgixParams: {
              fm: "webp"
              auto: "compress"
              maxW: 1080
              fit: "clip"
              q: 35
            }
          )
          fluid(imgixParams: { fm: "webp", auto: "compress", q: 35 }) {
            ...GatsbyDatoCmsFluid
          }
          alt
          url
        }
        metaTags {
          description
        }
        content {
          value
        }
        contentWordCounter
      }
    }
  }
`;

export default BlogCardDeck;
